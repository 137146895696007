import React                from "react"
import cx                   from 'utils/classnames'
import RawHtml              from 'entrada-ui/RawHtml'
import styles               from './styles.module.scss'

const COLORS = {
    'pink-card-label': ['#cf128c', '#f051b8'],
    'orange-card-label': ['#fc8300', '#f7a852'],
    'navy-card-label': ['#163194', '#0691dc'],
    'green-card-label': ['#00a754', '#35b876']
}

const SVG = ({ name, solid, soft }) => (`
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="57.017" height="38" viewBox="0 0 57.017 38">
        <defs>
            <style>.${name}-a{fill:none;}.${name}-b{clip-path:url(#${name}-a);}.${name}-c{fill:url(#${name}-b);}.${name}-d{fill:${solid};}</style>
            <clipPath id="${name}-a">
                <rect class="${name}-a" width="57.017" height="38" transform="translate(0 -0.03)"/>
            </clipPath>
            <linearGradient id="${name}-b" y1="19.916" x2="1" y2="19.916" gradientUnits="objectBoundingBox">
                <stop offset="0.854" stop-color="${soft}" stop-opacity="0.65"/>
            </linearGradient>
        </defs>
        <g class="${name}-b" transform="translate(0 0.03)">
            <path class="${name}-c" d="M-218.825-585.565c0-122.007-98.906-220.914-220.914-220.914s-220.913,98.907-220.913,220.914A220.745,220.745,0,0,0-557.1-398.391,147.036,147.036,0,0,1-439.738-456.7a147.041,147.041,0,0,1,117.367,58.309A220.748,220.748,0,0,0-218.825-585.565Z" transform="translate(346.746 423.269)"/>
            <path class="${name}-d" d="M-325.357-70.325A147.039,147.039,0,0,0-442.723-12.017,219.857,219.857,0,0,0-325.357,21.722,219.858,219.858,0,0,0-207.99-12.017,147.04,147.04,0,0,0-325.357-70.325Z" transform="translate(232.365 36.895)"/>
        </g>
    </svg>
`)

const CardLabel = (props) => {
    const {
        className,
        text,
        color: colorName = 'navy-card-label'
    } = props

    const color = COLORS[colorName]

    return (
        <div className={cx(styles.label, className)}>
            <div className={styles.text} style={{ backgroundColor: color[0] }}>
                {text}
            </div>

            <RawHtml html={SVG({ name: colorName, solid: color[0], soft: color[1] })} />
        </div>
    )
}

export default CardLabel
