import React                from 'react'
import Img                  from "gatsby-image"
import { map }              from 'lodash-es'
import { FaClock }          from 'react-icons/fa'
import {
  Container,
}                           from 'react-bootstrap'
import Alert                from 'react-bootstrap/Alert'
import RawHtml              from 'entrada-ui/RawHtml'
import Banner               from 'components/Banner'
import BookContact          from 'components/BookContact'
import Link                 from 'components/Link'
import TourCards            from 'components/TourCards'
import Seo                  from 'components/Seo'
import styles               from './styles.module.scss'

import { FIXED_FRAGMENT, FLUID_FRAGMENT } from 'utils/constants.js'

const TourCard = TourCards.CardWp

const ToursListTemplate = ({ pageContext: { data } }) => {
    const {
        siteMetadata,
        acf: {
            image: {
              localFile: {
                childImageSharp: {
                  mobileImage,
                  desktopImage
                }
              }
            },
            page_heading,
            overview,
            related_tours,
        },
        yoast_meta
    } = data

    const sources = [
      {
        ...mobileImage,
      },
      {
        ...desktopImage,
        media: `(min-width: 768px)`,
      },
    ]

    return (
      <>
        <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
        <Container>
          <h1 className={'page-title'}>{page_heading}</h1>
        </Container>

        <Container className={styles.containerSmallNoGutter}>
          {mobileImage &&
            <Img fluid={sources} className={styles.heroImage} />
          }
        </Container>

        <Container>

          {/* TODO This must be a "ReadMore" component, when you click you can read more. */}
          <div className={styles.content}>
            <RawHtml html={overview} />
          </div>

          <div className={styles.cardsHeading}>
            <h4>{related_tours.length} Day Tours to experience</h4>
            <Alert className={styles.alert} variant='dark' dismissible>
              <span>Free Cancellation</span>: Get a full refund up to 2 hours in advance on all our tours. <Link to="/">See Details</Link>
            </Alert>
          </div>
        </Container>

        <Container className={styles.containerSmallGutter}>
          <TourCards aside={true}>
          {map(related_tours, (tour, idx) => (
          <TourCard
            key={idx}
            {...tour}
          />
          ))}
          </TourCards>
        </Container>

        <Banner>
          <Banner.Icon fontSize='large'>
            <FaClock />
          </Banner.Icon>
          <Banner.Title>Free cancellation</Banner.Title>

          <Banner.Text>
            <p>You'll receive a full refund if you cancel at least 2 hours in advance of your tour experience.</p>
          </Banner.Text>
        </Banner>
      </>
    )
}

export default ToursListTemplate
