const HOME_URL = '/new-zealand-tours'

const BREAKPOINTS = {
    TABLET: 768,
    DESKTOP: 1024
}

export {
    HOME_URL,
    BREAKPOINTS
}
