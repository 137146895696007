/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Location } from "@reach/router"
import Helmet from "react-helmet"
import { find } from 'lodash-es'

function SEO({
  is404 = false,
  siteMetadata,
  meta,
}) {
  const {
    yoast_wpseo_title,
    yoast_wpseo_metadesc,
    yoast_wpseo_canonical,
    yoast_wpseo_facebook_title,
    yoast_wpseo_facebook_description,
    yoast_wpseo_facebook_type,
    yoast_wpseo_facebook_image,
    yoast_wpseo_twitter_title,
    yoast_wpseo_twitter_description,
    yoast_wpseo_twitter_image,
    yoast_wpseo_social_url,
    yoast_wpseo_company_or_person,
    yoast_wpseo_person_name,
    yoast_wpseo_company_name,
    yoast_wpseo_company_logo,
    yoast_wpseo_website_name,
    yoast_wpseo_social_defaults: {
      facebook_site,
      opengraph,
      og_default_image,
      og_frontpage_image,
      twitter,
      twitter_site,
      twitter_card_type
    },
    // This is a custom field that we added on functions.php
    yoast_schema
  } = meta

  return (
    <Location>
      {({ location }) => (
        <Helmet>
            <title>{yoast_wpseo_title}</title>

            {yoast_wpseo_metadesc &&
            <meta name="description" content={yoast_wpseo_metadesc}/>
            }

            {/* Facebook */}

            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />

            {opengraph &&
            <meta property="og:title" content={yoast_wpseo_facebook_title || yoast_wpseo_title} />
            }

            {(yoast_wpseo_facebook_description || yoast_wpseo_metadesc) &&
            <meta property="og:description" content={yoast_wpseo_facebook_description || yoast_wpseo_metadesc} />
            }

            <meta property="og:url" content={`${siteMetadata.siteUrl}${location.pathname}`} />

            {(yoast_wpseo_website_name || siteMetadata.title) &&
            <meta property="og:site_name" content={yoast_wpseo_website_name || siteMetadata.title} />
            }

            {(yoast_wpseo_facebook_image || og_default_image) &&
            <meta property="og:image" content={yoast_wpseo_facebook_image || og_default_image} />
            }

            {/* Twitter */}

            {twitter &&
            <meta name="twitter:card" content={twitter_card_type} />
            }

            {yoast_wpseo_twitter_description &&
            <meta name="twitter:description" content={yoast_wpseo_twitter_description} />
            }

            {twitter &&
            <meta name="twitter:title" content={yoast_wpseo_twitter_title || yoast_wpseo_title} />
            }

            {(yoast_wpseo_twitter_image || og_default_image) &&
            <meta name="twitter:image" content={yoast_wpseo_twitter_image || og_default_image} />
            }


            <script type='application/ld+json'>
              {JSON.stringify(JSON.parse(yoast_schema), null, 2)}
            </script>
        </Helmet>
      )}
    </Location>
  )
}

export default SEO
