import React from "react"
import Img from "gatsby-image"
import cx from "utils/classnames"
import { map } from "lodash-es"
import { FaClock, FaCheck, FaArrowLeft, FaArrowRight } from "react-icons/fa"
import Icon from "entrada-ui/Icon"
import CardLabel from "components/CardLabel"
import Link from "components/Link"
import styles from "./styles.module.scss"

const ListIcon = ({ children }) => (
    <Icon className={styles.listIcon} fontSize="small">
        {children}
    </Icon>
)

const TourCards = ({ aside, children }) => {
    const scrollableRef = React.useRef(null)
    const cardRef = React.useRef(null)

    const [cardSize, setCardSize] = React.useState(0)

    const onClickLeft = ev => {
        scrollableRef.current.scrollLeft = Math.max(
            scrollableRef.current.scrollLeft - cardSize,
            0
        )
    }

    const onClickRight = ev => {
        scrollableRef.current.scrollLeft += cardSize
    }

    React.useEffect(() => {
        if (cardRef) {
            const cardStyle = window.getComputedStyle(cardRef.current)
            const marginRight = cardStyle.getPropertyValue("margin-right")

            setCardSize(cardRef.current.clientWidth + parseInt(marginRight))
        }
    }, [cardRef])

    return (
        <div className={cx(styles.cards, aside && styles.aside)}>
            {!aside && (
                <>
                    <button
                        className={cx(styles.btn, styles.btnLeft)}
                        onClick={onClickLeft}
                    >
                        <Icon className={styles.btnIcon}>
                            <FaArrowLeft />
                        </Icon>
                    </button>
                    <button
                        className={cx(styles.btn, styles.btnRight)}
                        onClick={onClickRight}
                    >
                        <Icon className={styles.btnIcon}>
                            <FaArrowRight />
                        </Icon>
                    </button>
                </>
            )}
            <div ref={scrollableRef} className={!aside ? styles.scrollable : undefined}>
                {React.Children.map(children, (child, idx) => (
                    <child.type
                        aside={aside}
                        {...child.props}
                        ref={idx === 0 ? cardRef : undefined}
                    />
                ))}
            </div>
        </div>
    )
}

TourCards.Card = React.forwardRef(function TourCardsCard(props, ref) {
    const {
        className,
        title,
        overview,
        url,
        label,
        image,
        departure,
        duration,
        features,
        price,
        combo,
        aside = false,
    } = props

    return (
        <div ref={ref} className={cx(styles.card, className)}>
            <Link to={url}>
                <div className={styles.imageCol}>
                    <Img
                        fluid={{
                            ...image,
                            // aspectRatio: 4 / 3
                        }}
                        className={styles.cardImage}
                    />

                    {label && label.color && (
                        <CardLabel
                            className={styles.cardLabel}
                            text={label.title}
                            color={label.color}
                        />
                    )}
                </div>
            </Link>
            <div className={styles.body}>
                <div className={styles.cardHeader}>
                    {departure && (
                        <div className={styles.departure}>
                            From {departure}
                        </div>
                    )}
                    <h3 className={styles.title}>
                        <Link to={url}>{title}</Link>
                    </h3>
                </div>

                {aside && <div className={styles.overview}>{overview}</div>}

                <ul className={styles.list}>
                    <li>
                        {duration && (
                            <>
                                <ListIcon>
                                    <FaClock />
                                </ListIcon>
                                { combo ? 
                                    'Two-day tour combo' : 
                                    `${duration.hours} hours ` + (duration.minutes != null ? `${duration.minutes} minutes` : '')
                                }
                            </>
                        )}
                    </li>

                    {map(features, (feature, idx) => (
                        <li key={idx}>
                            <ListIcon>
                                <FaCheck />
                            </ListIcon>
                            {feature}
                        </li>
                    ))}
                </ul>

                <p className={styles.priceSummary}>
                    from <span className={styles.price}>${price} NZD</span>
                </p>
            </div>
        </div>
    )
})

TourCards.CardWp = React.forwardRef(function TourCardsCardWp(props, ref) {
    const {
        url,
        acf: {
            adult_price,
            card_features,
            card_image,
            card_label_color,
            card_label_text,
            card_title,
            card_overview,
            departure,
            tour_duration_hours,
            tour_duration_minutes,
            combo_tour
        },
        ...rest
    } = props

    return (
        <TourCards.Card
            {...rest}
            ref={ref}
            title={card_title}
            overview={card_overview}
            url={url}
            label={{
                title: card_label_text,
                color: card_label_color
            }}
            image={(card_image?card_image.localFile.childImageSharp.fluid:undefined)}
            departure={departure}
            duration={{
                hours: tour_duration_hours,
                minutes: tour_duration_minutes
            }}
            combo={combo_tour}
            features={card_features.map(feature => feature.card_feature)}
            price={adult_price}
        />
    )
})

export default TourCards
