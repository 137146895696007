import React                  from 'react'
import cx                     from 'utils/classnames'
import {
    Container
}                             from 'react-bootstrap'
import Icon                   from 'entrada-ui/Icon'
import Link                   from 'components/Link'
import styles                 from './styles.module.scss'

const Banner = ({
    className,
    children
}) => (
    <div className={cx(styles.banner, className)}>
        <Container style={{ maxWidth: 420 }}>
            {children}
        </Container>
    </div>
)

Banner.Icon = ({ className, children, ...props }) => (
    <Icon className={cx(styles.icon, className)} {...props}>{children}</Icon>
)

Banner.Title = ({ children, ...props }) => <h3 className={styles.title} {...props}>{children}</h3>
Banner.Button = ({ children, ...props }) => (
    <Link className={cx('btn btn-warning btn-lg', styles.button)} {...props}>
        {children}
    </Link>
)

Banner.Text = ({ children, ...props }) => <div className={styles.text} {...props}>{children}</div>

export default Banner
