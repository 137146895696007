import React                from 'react'
import cx                   from 'classnames'
import { FaPhone }          from 'react-icons/fa'
import Typography           from 'entrada-ui/Typography'
import styles               from './styles.module.scss'

const BookContact = ({ className, big = false }) => (
  <div className={cx(styles.bookContact, big && styles.big, className)}>
    {big ?
    <>
      <Typography className="d-flex align-items-center" variant="h4" color="brand-color-2">
        <FaPhone className={styles.bookContactIcon} /> <a href="tel:0800744487">0800 744 487</a>
      </Typography>

      <p>
        International Calls: <a href="tel:+6495835790">+64 9 583 5790</a>
        Our contact centre is open 6.30am-8pm daily (NZDT).
      </p>
    </>
    :
    <>
      <FaPhone className={styles.bookContactIcon} /> Book online or call: <a href="tel:0800744487">0800 744 487</a> or <a href="tel:+6495835790">+64 9 583 5790</a>
    </>

    }
  </div>
)

export default BookContact
