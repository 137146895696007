import React                from 'react'
import cx                   from 'utils/classnames'
// NOTE That we are importing the styles from the 'theme', so we need to
// define all the styles in the theme level, there's no default style for
// Typography.

const defaultVariantTagMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    subtitle: 'h6',
    body: 'p',
};

const Typography = React.forwardRef(function Typography(props, ref) {
    const {
        align = 'inherit',
        classes,
        className,
        component,
        variant = 'body',
        color = 'initial',
        ...rest
    } = props;

    const Component = component || defaultVariantTagMapping[variant]

    return (
        <Component
            className={cx(
                {
                    [variant]: variant !== 'inherit' && variant !== Component,
                    [`text-align-${align}`]: align !== 'inherit',
                    [`text-color-${color}`]: color !== 'initial',
                },
                className,
            )}
            ref={ref}
            {...rest}
        />
    )
})

export default Typography
